module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"サークルアラウンド株式会社","short_name":"サークルアラウンド","start_url":"/","background_color":"#ffffff","theme_color":"#00589d","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"70ccc2ddf7977c62d2c4e3f26b8bb079"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"pathPrefix":"/","autoGenHomeLabel":"HOME","exclude":["/dev-404-page","/404","/404.html"],"crumbLabelUpdates":[{"pathname":"/recruit","crumbLabel":"求人"},{"pathname":"/contact","crumbLabel":"お問い合わせ"},{"pathname":"/company","crumbLabel":"会社概要"},{"pathname":"/vision","crumbLabel":"ビジョン"},{"pathname":"/member","crumbLabel":"メンバー"},{"pathname":"/service","crumbLabel":"サービス"},{"pathname":"/privacy","crumbLabel":"プライバシーポリシー"},{"pathname":"/blog","crumbLabel":"ブログ"},{"pathname":"/news","crumbLabel":"ニュース"},{"pathname":"/thanks","crumbLabel":"送信完了"},{"pathname":"/workstyle","crumbLabel":"ワークスタイル"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
