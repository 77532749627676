// custom typefaces
import "@fontsource/montserrat/variable.css"
import "@fontsource/merriweather"
import "./src/styles/reset.css"

// custom CSS styles
import "./src/styles/theme.scss"
// Highlighting for code blocks
import "prismjs/themes/prism.css"

export const onRouteUpdate = ({ location }) => {
  console.log("new pathname", location.pathname)
  if (location.pathname !== "/service/") {
    document.documentElement.classList.add("v1")
  } else {
    document.documentElement.classList.remove("v1")
  }
}
